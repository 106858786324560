var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-page-header',{attrs:{"title":_vm.title},on:{"back":function () { return _vm.$router.go(-1); }}}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"main-content-header"},[_c('div',{staticClass:"item flex"},[_c('div',{staticClass:"title"},[_vm._v("服务项目")]),_c('div',{staticClass:"item area-cascader"},[_c('span',{staticClass:"ribbon-label"},[_vm._v("按地区查询：")]),_c('a-cascader',{attrs:{"options":_vm.cascaderAreaOptions,"placeholder":"选择地区","expand-trigger":"hover","field-names":_vm.fieldNames},model:{value:(_vm.areaId),callback:function ($$v) {_vm.areaId=$$v},expression:"areaId"}})],1)]),_c('div',{staticClass:"item"},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary"},on:{"click":_vm.onSearchs}},[_vm._v(" 查 询 ")]),_c('a-button',{staticStyle:{"margin-right":"10px"},on:{"click":_vm.onReset}},[_vm._v(" 重 置 ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$jump({
              path: '/serviceEdit',
              query: {
                id: _vm.categoryId,
                bcid: _vm.bcid,
                name: _vm.title,
                areaId: _vm.areaId
              }
            })}}},[_vm._v(" 添加服务项目 ")])],1)]),_c('my-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"loading":_vm.loading,"pagination":false,"scroll":{ x: 1160 }},scopedSlots:_vm._u([{key:"cover",fn:function(record){return [_c('div',{staticClass:"cover"},[_c('img',{staticClass:"img",attrs:{"src":record.cover,"alt":"cover"}})])]}},{key:"status",fn:function(record){return [_c('a-badge',{attrs:{"status":_vm.statuText[record.status].value,"text":_vm.statuText[record.status].label}})]}},{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.setStatus({
              servingId: record.id,
              status: record.status,
              type: 1
            })}}},[(record.status === 1)?_c('span',[_vm._v("下架")]):_c('span',[_vm._v("上架")])])]}},{key:"expandedRowRender",fn:function(innerRecord, serviceIndex){return _c('a-table',{staticClass:"inner-table",attrs:{"columns":_vm.innerColumns,"data-source":innerRecord.info,"pagination":false,"row-key":function (record) { return record.id; },"scroll":{ x: 1160 }},scopedSlots:_vm._u([{key:"cover",fn:function(records){return [_c('div',{staticClass:"cover"},[_c('img',{staticClass:"img",attrs:{"src":records.cover,"alt":"cover"}})])]}},{key:"price",fn:function(records){return [_c('a-statistic',{attrs:{"prefix":"￥","value":records.price}})]}},{key:"status",fn:function(records){return [_c('a-badge',{attrs:{"status":_vm.statuText[records.status].value,"text":_vm.statuText[records.status].label}})]}},{key:"realprice",fn:function(text, records){return [(records.editable)?_c('a-input',{staticClass:"realprice-input",attrs:{"placeholder":"实际售价"},model:{value:(_vm.newRealprice),callback:function ($$v) {_vm.newRealprice=$$v},expression:"newRealprice"}}):[(records.realprice)?_c('a-statistic',{attrs:{"prefix":"￥","value":records.realprice}}):_c('span',[_vm._v("-")])]]}},{key:"action",fn:function(text, records, index){return [(!records.editable)?_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.setStatus({
                servingId: records.servingId,
                infoId: records.id,
                status: records.status,
                type: 2
              })}}},[(records.status === 1)?_c('span',[_vm._v("下架")]):_c('span',[_vm._v("上架")])]):_vm._e(),_c('div',{staticClass:"editable-row-operations"},[(records.editable)?_c('span',[_c('a-button',{attrs:{"type":"link"},on:{"click":function () { return _vm.saveRealprice(serviceIndex, index); }}},[_vm._v("保存")]),_c('a-popconfirm',{attrs:{"title":"确定取消?","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function () { return _vm.cancelRealprice(serviceIndex, index); }}},[_c('a-button',{attrs:{"type":"link"}},[_vm._v("取消")])],1)],1):_c('a-button',{attrs:{"type":"link"},on:{"click":function () { return _vm.editRealprice(serviceIndex, index); }}},[_vm._v(" 编辑售价 ")])],1)]}}])})}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- 服务项目 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)" />
    <div class="main-content">
      <div class="main-content-header">
        <div class="item flex">
          <div class="title">服务项目</div>
          <div class="item area-cascader">
            <span class="ribbon-label">按地区查询：</span>
            <a-cascader
              :options="cascaderAreaOptions"
              placeholder="选择地区"
              expand-trigger="hover"
              :field-names="fieldNames"
              v-model="areaId"
            />
          </div>
        </div>
        <div class="item">
          <a-button
            type="primary"
            style="margin-right: 10px"
            @click="onSearchs"
          >
            查 询
          </a-button>
          <a-button style="margin-right: 10px" @click="onReset">
            重 置
          </a-button>
          <a-button
            type="primary"
            @click="
              $jump({
                path: '/serviceEdit',
                query: {
                  id: categoryId,
                  bcid: bcid,
                  name: title,
                  areaId: areaId
                }
              })
            "
          >
            添加服务项目
          </a-button>
        </div>
      </div>
      <my-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        :scroll="{ x: 1160 }"
      >
        <template slot="cover" slot-scope="record">
          <div class="cover">
            <img class="img" :src="record.cover" alt="cover" />
          </div>
        </template>
        <template slot="status" slot-scope="record">
          <a-badge
            :status="statuText[record.status].value"
            :text="statuText[record.status].label"
          />
        </template>

        <template slot="action" slot-scope="record">
          <a-button
            type="link"
            @click="
              setStatus({
                servingId: record.id,
                status: record.status,
                type: 1
              })
            "
          >
            <span v-if="record.status === 1">下架</span>
            <span v-else>上架</span>
          </a-button>
        </template>

        <a-table
          class="inner-table"
          slot="expandedRowRender"
          slot-scope="innerRecord, serviceIndex"
          :columns="innerColumns"
          :data-source="innerRecord.info"
          :pagination="false"
          :row-key="(record) => record.id"
          :scroll="{ x: 1160 }"
        >
          <template slot="cover" slot-scope="records">
            <div class="cover">
              <img class="img" :src="records.cover" alt="cover" />
            </div>
          </template>
          <template slot="price" slot-scope="records">
            <a-statistic prefix="￥" :value="records.price" />
          </template>
          <template slot="status" slot-scope="records">
            <a-badge
              :status="statuText[records.status].value"
              :text="statuText[records.status].label"
            />
          </template>
          <!-- 可编辑单元格-start -->
          <template slot="realprice" slot-scope="text, records">
            <a-input
              class="realprice-input"
              placeholder="实际售价"
              v-if="records.editable"
              v-model="newRealprice"
            />
            <template v-else>
              <a-statistic
                prefix="￥"
                :value="records.realprice"
                v-if="records.realprice"
              />
              <span v-else>-</span>
            </template>
          </template>
          <!-- 可编辑单元格-end -->
          <template slot="action" slot-scope="text, records, index">
            <a-button
              type="link"
              @click="
                setStatus({
                  servingId: records.servingId,
                  infoId: records.id,
                  status: records.status,
                  type: 2
                })
              "
              v-if="!records.editable"
            >
              <span v-if="records.status === 1">下架</span>
              <span v-else>上架</span>
            </a-button>
            <div class="editable-row-operations">
              <span v-if="records.editable">
                <a-button
                  type="link"
                  @click="() => saveRealprice(serviceIndex, index)"
                  >保存</a-button
                >
                <a-popconfirm
                  title="确定取消?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="() => cancelRealprice(serviceIndex, index)"
                >
                  <a-button type="link">取消</a-button>
                </a-popconfirm>
              </span>
              <a-button
                type="link"
                v-else
                @click="() => editRealprice(serviceIndex, index)"
              >
                编辑售价
              </a-button>
            </div>
          </template>
        </a-table>
      </my-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      categoryId: '',
      bcid: '',
      title: '',
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '排序',
          dataIndex: 'sort',
          width: 60
        },
        {
          title: '服务名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '缩略图',
          scopedSlots: { customRender: 'cover' },
          width: 100
        },
        {
          title: '上架状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 100,
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      innerColumns: [
        {
          title: '排序',
          dataIndex: 'sort',
          width: 60
        },
        {
          title: '编号',
          dataIndex: 'goods_tag',
          width: 100
        },
        {
          title: '设备名称',
          dataIndex: 'name',
          width: 150
        },
        {
          title: '设备属性',
          dataIndex: 'subtitle',
          width: 250
        },
        {
          title: '缩略图',
          scopedSlots: { customRender: 'cover' },
          width: 100
        },
        {
          title: '上架状态',
          scopedSlots: { customRender: 'status' },
          width: 100
        },
        {
          title: '默认售价',
          scopedSlots: { customRender: 'price' },
          width: 100
        },
        {
          title: '实际售价',
          scopedSlots: { customRender: 'realprice' }
        },
        {
          title: '操作',
          width: 180,
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      loading: true,
      innerDataIndex: 0,
      statuText: {
        1: {
          value: 'success',
          label: '上架'
        },
        0: {
          value: 'default',
          label: '下架'
        }
      },
      newRealprice: '',
      tabIndex: '',
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      cascaderAreaOptions: [],
      areaId: []
    }
  },
  created() {
    const query = this.$route.query
    this.categoryId = query.id
    this.bcid = query.bcid
    this.title = query.name
    this.tabIndex = query.tabIndex
    console.log('this.tabIndex', this.tabIndex)
    if (this.tabIndex == 0) {
      this.getMainServiceItem()
    }
    this.getArea()
  },
  methods: {
    async getArea() {
      // 获取区域列表
      const area = await this.$axios.getArea()
      this.cascaderAreaOptions = area.nested
    },
    getMainServiceItem() {
      // 获取主线的服务项目
      const data = {
        bcid: this.bcid,
        categoryId: this.categoryId
      }
      if (this.tabIndex == 0) {
        this.$axios.getMainServiceItem(data).then((res) => {
          this.dataSource = res.data.data
          this.loading = false
        })
      }
    },
    onSearchs() {
      // 高级搜索
      this.loading = true
      this.getBcAllServingInfo()
    },
    onReset() {
      // 重置时间
      this.areaId = []
      //   this.getCityCateServing()
    },
    getBcAllServingInfo() {
      const data = {
        categoryId: this.categoryId,
        areaId: this.areaId[this.areaId.length - 1],
        bcid: this.bcid
      }
      this.$axios.getBuserving(data).then((res) => {
        console.log('resffff', res.data.data)
        this.dataSource = res.data.data
        this.loading = false
      })
    },
    setStatus(e) {
      // 设置上下架状态
      let title = ''
      if (e.status === 1) {
        title = '确定下架？'
        e.status = 0
      } else {
        title = '确定上架？'
        e.status = 1
      }
      let areaId = this.areaId[this.areaId.length - 1]
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            type: e.type, // 1:服务 2：设备
            bcid: this.bcid, // 业务线配置id
            servingId: e.servingId, // 服务项目id
            status: e.status, // 状态 1:上架：是开启，  0:下架  2:禁用
            areaId: areaId || undefined
          }
          if (e.type === 2) {
            data.infoId = e.infoId // 服务项目设备id
          }
          if (this.areaId.length != 0) {
            this.$axios.getUpBcStatus(data).then(() => {
              this.$message.success('操作成功')
              this.getBcAllServingInfo()
            })
          } else {
            this.$axios.setServiceStatus(data).then(() => {
              this.$message.success('操作成功')
              this.getMainServiceItem()
            })
          }
        }
      })
    },
    editRealprice(serviceIndex, index) {
      // 编辑
      const item = this.dataSource[serviceIndex]
      const target = item.info[index]
      target.editable = true
      item.info.splice(index, 1, target)
    },
    saveRealprice(serviceIndex, index) {
      // 保存
      const item = this.dataSource[serviceIndex]
      const target = item.info[index]
      target.realprice = this.newRealprice
      delete target.editable
      item.info.splice(index, 1, target)
      this.saveData(target, item)
    },
    cancelRealprice(serviceIndex, index) {
      // 取消
      const item = this.dataSource[serviceIndex]
      const target = item.info[index]
      delete target.editable
      item.info.splice(index, 1, target)
      this.newRealprice = ''
    },
    saveData(target, item) {
      let areaId = this.areaId[this.areaId.length - 1]
      // 保存修改
      const data = {
        infoId: target.id,
        servingId: target.servingId,
        realprice: this.newRealprice,
        bcid: item.bcid,
        areaId: areaId || undefined
      }
      console.log(data, item)
      if (this.areaId.length != 0) {
        this.$axios.getEditInfoAred(data).then(() => {
          this.$message.success('操作成功')
          this.getBcAllServingInfo()
        })
      } else {
        this.$axios.getEditInfo(data).then(() => {
          this.$message.success('操作成功')
          this.getMainServiceItem()
        })
      }
    }
  }
}
</script>
<style scoped>
.plus-link {
  color: #fff;
}

.cover {
  width: 50px;
  height: 50px;
}

.inner-table {
  background-color: #fafafa;
}

.inner-table .editable-row-operations {
  display: inline-block;
}

.inner-table .realprice-input {
  width: 100px;
}
.area-cascader {
  margin-top: 10px;
}
.ribbon-label {
  font-size: 14px;
  font-weight: bold;
}
</style>
